var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"update-pwd"},[(!(_vm.employerConfig && _vm.employerConfig.hidTitle))?_c('u-page-back',{attrs:{"title":"修改登录密码"}}):_vm._e(),_c('div',{staticClass:"update-pwd-img"},[(_vm.userInfo.avatar)?_c('img',{staticClass:"update-pwd-img-warp",attrs:{"src":_vm.userInfo.avatar}}):_c('img',{staticClass:"update-pwd-img-warp",attrs:{"src":_vm.orgInfo.logo}})]),_c('div',{staticClass:"update-pwd-form"},[_c('van-form',{on:{"submit":_vm.onSave}},[_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"left","type":_vm.state.showOldPassword ? 'text' : 'password',"rules":[{ required: true, message: '请输入旧密码', trigger: 'onBlur' }],"placeholder":"请输入旧密码"},model:{value:(_vm.modelData.oldPassword),callback:function ($$v) {_vm.$set(_vm.modelData, "oldPassword", $$v)},expression:"modelData.oldPassword"}},[_c('template',{slot:"right-icon"},[(_vm.state.showOldPassword)?_c('van-icon',{attrs:{"name":"eye-o"},on:{"click":function($event){_vm.state.showOldPassword = !_vm.state.showOldPassword}}}):_vm._e(),(!_vm.state.showOldPassword)?_c('van-icon',{attrs:{"name":"closed-eye"},on:{"click":function($event){_vm.state.showOldPassword = !_vm.state.showOldPassword}}}):_vm._e()],1)],2),_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"left","type":_vm.state.showPassword ? 'text' : 'password',"rules":[
          { required: true, message: '请输入新的密码', trigger: 'onBlur' },
          { validator: _vm.validatorpwdLength, message: '密码长度在6-32之间', trigger: 'onBlur' },
        ],"placeholder":"请输入新的密码（6-32位）"},model:{value:(_vm.modelData.password),callback:function ($$v) {_vm.$set(_vm.modelData, "password", $$v)},expression:"modelData.password"}},[_c('template',{slot:"right-icon"},[(_vm.state.showPassword)?_c('van-icon',{attrs:{"name":"eye-o"},on:{"click":function($event){_vm.state.showPassword = !_vm.state.showPassword}}}):_vm._e(),(!_vm.state.showPassword)?_c('van-icon',{attrs:{"name":"closed-eye"},on:{"click":function($event){_vm.state.showPassword = !_vm.state.showPassword}}}):_vm._e()],1)],2),_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"left","type":_vm.state.showConfirm ? 'text' : 'password',"rules":[
          { required: true, message: '请输入确认新密码', trigger: 'onBlur' },
          { validator: _vm.validatorPwd, message: '两次密码输入不一致', trigger: 'onBlur' },
        ],"placeholder":"请再次输入新密码"},model:{value:(_vm.modelData.confirm),callback:function ($$v) {_vm.$set(_vm.modelData, "confirm", $$v)},expression:"modelData.confirm"}},[_c('template',{slot:"right-icon"},[(_vm.state.showConfirm)?_c('van-icon',{attrs:{"name":"eye-o"},on:{"click":function($event){_vm.state.showConfirm = !_vm.state.showConfirm}}}):_vm._e(),(!_vm.state.showConfirm)?_c('van-icon',{attrs:{"name":"closed-eye"},on:{"click":function($event){_vm.state.showConfirm = !_vm.state.showConfirm}}}):_vm._e()],1)],2),_c('van-button',{staticClass:"btn btn-block update-btn",attrs:{"type":"onSave"}},[_vm._v("确认修改")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }