
import { Component, Vue } from "vue-property-decorator";
import { UserService } from "src/services";
import { Toast } from "vant";
import patterns from "src/utils/patterns";
import globalConfig from "src/config/index";

@Component
export default class UpdatePwd extends Vue {
  /**
   * 修改数据模型
   * @private
   * @returns string
   */
  private modelData: any = {
    oldPassword: "",
    password: "",
    confirm: "",
  };

  /**
   * 密码显示方式集合
   * @private
   * @returns string
   */
  private state: any = {
    showOldPassword: "",
    showPassword: "",
    showConfirm: "",
  };

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 验证两次密码是否一直
   * @private
   *
   * @returns void
   */
  private validatorPwd(val: any): any {
    return this.modelData.password === val;
  }

  /**
   * 验证密码长度
   * @private
   * @returns void
   */
  private validatorpwdLength(val: any): any {
    return val.length >= 6 && val.length <= 20;
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 保存
   * @private
   * @returns void
   */
  private async onSave(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.updatePwd(this.modelData);
      Toast("修改密码成功");
      window.localStorage.clear();
      this.$router.push({ name: "login" });
    } catch (err) {
      Toast(err);
    }
  }
}
