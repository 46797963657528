import { render, staticRenderFns } from "./update-pwd.vue?vue&type=template&id=f889c8c6&scoped=true"
import script from "./update-pwd.vue?vue&type=script&lang=ts"
export * from "./update-pwd.vue?vue&type=script&lang=ts"
import style0 from "./update-pwd.vue?vue&type=style&index=0&id=f889c8c6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f889c8c6",
  null
  
)

export default component.exports