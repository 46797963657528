
import { Component, Vue } from "vue-property-decorator";
import { LocationcityService, AddressService } from "src/services";
import { Toast, Dialog } from "vant";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import patterns from "src/utils/patterns";
import AddressParse from "zh-address-parse";
import globalConfig from "src/config/index";
import router from "src/router";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class AddressDetail extends Vue {
  /**
   * 清除收货地址
   * @private
   * @returns
   */
  protected clearAddress() {
    this.userAddress = "";
  }

  /**
   * 识别收货地址的配置信息
   * @private
   * @returns
   */
  protected options: any = {
    type: 0, // 哪种方式解析，0：正则，1：树查找
    textFilter: [], // 预清洗的字段
    nameMaxLength: 4, // 查找最大的中文名字长度
    extraGovData: {
      city: [{ name: "name", code: "code", provinceCode: "provinceCode" }],
      province: [{ name: "name", code: "code" }],
      area: [
        {
          name: "name",
          code: "code",
          provinceCode: "provinceCode",
          cityCode: "cityCode",
        },
      ],
    },
  };

  /**
   * 识别收货地址
   * @private
   * @returns
   */
  protected async ocrAddress() {
    try {
      const addressText = await navigator.clipboard.readText();
      this.userAddress = addressText;
      if (this.userAddress.trim() == "") {
        Toast("请输入有效地址！");
        return;
      }
    } catch (err) {
      console.error("粘贴失败", err);
    }

    const parseResult = AddressParse(this.userAddress, this.options);

    this.formData.receiverName = parseResult.name;
    this.formData.phone = parseResult.phone;
    this.formData.address = parseResult.detail;

    if (this.areaList && this.areaList.province_list) {
      // 赋值省code
      for (let key in this.areaList.province_list) {
        if (this.areaList.province_list[key] == parseResult.province) {
          this.formData.provinceCode = key;
        }
      }
    }
    if (this.areaList && this.areaList.city_list) {
      // 赋值市code
      for (let key in this.areaList.city_list) {
        if (this.areaList.city_list[key] == parseResult.city) {
          this.formData.cityCode = key;
        }
      }
    }
    if (this.areaList && this.areaList.county_list) {
      // 赋值区code
      for (let key in this.areaList.county_list) {
        if (this.areaList.county_list[key] == parseResult.area) {
          this.formData.areaCode = key;
        }
      }
    }

    this.formData.provinceName = parseResult.province;
    this.formData.cityName = parseResult.city;
    this.formData.areaName = parseResult.area;

    if (parseResult.area) {
      this.areaStr = parseResult.province + "/" + parseResult.city + "/" + parseResult.area;
    } else {
      this.areaStr = parseResult.province + "/" + parseResult.city;
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 是否设置为默认收货地址
   * @private
   * @returns
   */
  protected async onSetDefault(id: any): Promise<any> {
    try {
      let { content: res } = await AddressService.instance.setAddressDefault(id);
      // Toast("设置成功");
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 是否设置为默认收货地址
   * @private
   * @returns
   */
  protected async switchChange(isDefault): Promise<any> {
    try {
      if (isDefault) {
        this.onSetDefault(this.formData.id);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 是否确定删除
   * @private
   * @returns
   */
  protected async onConfirmDelete(): Promise<any> {
    try {
      if (!this.id) {
        return;
      }
      let { content: res } = await AddressService.instance.deleteAddress(this.id);
      Toast("删除成功");
      this.$router.go(-1);
    } catch (err) {
      Toast(err);
    } finally {
      this.showDialog = false;
    }
  }

  /**
   * 手动粘贴地址
   * @private
   * @returns
   */
  private userAddress: string = "";

  /**
   * 是否显示删除确定弹框
   * @private
   * @returns
   */
  private showDialog: boolean = false;

  /**
   * 是否默认为收货地址
   * @private
   * @return boolean
   */
  private checked: boolean = false;

  /**
   * 是否显示区域弹框
   * @private
   * @return boolean
   */
  private showArea: boolean = false;

  /**
   * 路径
   * @private
   * @return boolean
   */
  private path: string = "";

  /**
   * 区域code
   * @private
   * @return boolean
   */
  private valueCode: string = "";

  /**
   * 区域字符串
   * @private
   * @return string
   */
  private areaStr: string = "";

  /**
   * 所有区域id集合
   * @private
   * @return string
   */
  private areaListId: any = {};

  /**
   * 区域列表
   * @private
   * @return  any
   */
  private areaList: any = {
    city_list: {},
    county_list: {},
    province_list: {},
  };

  private formData: any = {
    id: 0,
    provinceId: "",
    provinceCode: "",
    provinceName: "",
    cityId: null,
    cityCode: "",
    cityName: "",
    areaId: null,
    areaCode: "",
    areaName: "",
    address: "",
    fullAddress: "",
    postCode: "",
    receiverName: "",
    phone: "",
    isDefaultAddress: false,
  };

  /**
   * 地址id
   * @private
   * @returns number
   */
  private get id(): any {
    if (this.$route.query && this.$route.query.id) {
      return this.$route.query.id + "";
    }

    return null;
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    localStorage.setItem("routePath", from.path);
    next();
  }

  /**
   * 组件创建钩子
   * @private
   * @return  void
   */
  private created() {
    this.getRegion();
    if (this.id) {
      this.getAddressDetail(this.id);
    }
  }

  /**
   * 验证是否正确手机号
   * @private
   * @returns void
   */
  private validatorPhone(val: any): any {
    return patterns.mobile.test(val);
  }
  // 提交
  private async onSubmit() {
    let routePath = localStorage.getItem("routePath");

    try {
      if (!this.formData.provinceName || !this.formData.cityName) {
        Toast("请输入省市区");
        return;
      }

      this.formData.areaId = this.areaListId[this.formData.areaCode];
      this.formData.cityId = this.areaListId[this.formData.cityCode];
      this.formData.provinceId = this.areaListId[this.formData.provinceCode];
      let { content: result } = await AddressService.instance.modifyAddress(this.formData);

      // 收货地址的id
      const addressId = result.data;
      const orderId = this.$route.query.orderId;

      // 确认订单状态更新地址

      if (routePath == "/goods/order/detail") {
        Toast("新建成功！");
        this.$router.replace({ name: "goods-order-detail", query: { id: orderId, addressId: addressId } });
        return;
      } else {
        if (this.formData.id) {
          router.go(-1);
        } else {
          router.go(-1);
        }
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  // 确认选择省市区
  private onConfirm(values): void {
    this.areaStr = values
      .filter((item) => !!item)
      .map((item) => item.name)
      .join("/");
    this.formData.provinceCode = values[0] ? values[0].code : "";
    this.formData.provinceName = values[0] ? values[0].name : "";
    this.formData.cityCode = values[1] ? values[1].code : "";
    this.formData.cityName = values[1] ? values[1].name : "";
    this.formData.areaCode = values[2] ? values[2].code : "";
    this.formData.areaName = values[2] ? values[2].name : "";
    this.showArea = false;
  }

  // 取消
  private onCancel(): void {
    this.$router.go(-1);
  }

  // 提交
  private async getRegion() {
    try {
      // 新建
      let { content: result } = await LocationcityService.instance.getRegion();
      if (result.data) {
        for (let key in result.data) {
          if (result.data[key]) {
            for (let key1 in result.data[key]) {
              this.areaList[key][key1] = result.data[key][key1]["name"] || "";
              this.areaListId[key1] = result.data[key][key1]["id"] || null;
            }
          } else {
            this.areaList[key] = {};
          }
        }
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  // 提交
  private async getAddressDetail(id) {
    try {
      let { content: result } = await AddressService.instance.getAddressDetail(id);
      if (result.data) {
        this.formData.id = result.data.id;
        this.formData.provinceId = result.data.provinceId;
        this.formData.provinceCode = result.data.provinceCode;
        this.formData.provinceName = result.data.provinceName;
        this.formData.cityId = result.data.cityId;
        this.formData.cityCode = result.data.cityCode;
        this.formData.cityName = result.data.cityName;
        this.formData.areaId = result.data.areaId;
        this.formData.areaCode = result.data.areaCode;
        this.formData.areaName = result.data.areaName;
        this.formData.address = result.data.address;
        this.formData.receiverName = result.data.receiverName;
        this.formData.phone = result.data.phone;
        this.formData.isDefaultAddress = result.data.isDefaultAddress;
        this.areaStr = `${result.data.provinceName}/${result.data.cityName}/${result.data.areaName}`;
        this.checked = result.data.isDefaultAddress;
      }
      // value
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
