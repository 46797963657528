
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { FamilyService, FileService, UserService } from "src/services";
import dayjs from "dayjs";
import globalConfig from "src/config/index";

@Component
export default class UserSetting extends Vue {
  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 文件列表
   * @private
   * @returns void
   */
  private fileList: any = [];

  /**
   * 退出登录
   * @private
   * @returns void
   */
  private logoutDialog: boolean = false;

  /**
   * 联系人数据模型
   * @private
   *
   */
  private modelData: any = {
    id: null,
    avatar: "",
    memberType: 2,
    memberName: "", // 用户姓名
    familyType: null, // 用户关系
    familyTypeName: "", // 用户关系名称
    phone: "", // 手机号
    sex: 0, // 用户性别
    birthday: null, // 生日
    age: null, // 年龄
    idTypeName: null,
    identityCardType: null, // 证件类型
    marriageStatus: 1, // 用户婚姻状态
    cardNo: "", // 证件号
    remark: "", // 备注
  };

  /**
   * 跳转修改密码页面
   * @private
   * @returns void
   */
  private onModifyPwd(): void {
    this.$router.push({ name: "updatePwd" });
  }

  /**
   * 修改手机号
   * @private
   * @returns void
   */
  private onModifyPhone(): void {
    this.$router.push({ name: "updatePhone", query: { isPerfect: "1" } });
  }

  /**
   * 格式化日期
   * @private
   * @returns void
   */
  private formatBirthday(date): string {
    if (dayjs(date).isValid()) {
      return dayjs(date).format("YYYY/MM/DD");
    } else {
      return "";
    }
  }

  /**
   * 文件超出大小
   * @private
   * @returns void
   */
  private onOversize(file: any): void {
    Toast("图片超出10M,请重新调整后上传");
  }

  /**
   * 文件读取完成后的回调函数
   * @private
   * @returns void
   */
  private async onAfterRead(file: any): Promise<void> {
    try {
      let formData = new FormData();
      formData.append("file", file.file);
      let { content: res } = await UserService.instance.uploadAvatar(formData);
      this.modelData.avatar = res.data;
      let data = Object.assign({}, this.userInfo);
      data.avatar = res.data;
      this.$store.dispatch("setUserInfo", data);
      Toast("修改成功!");
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 退出登录
   * @private
   * @returns void
   */
  private async onSignOut(): Promise<void> {
    this.logoutDialog = true;

    // Dialog.confirm({
    //     message: "是否退出登录",
    //     confirmButtonText: "是",
    //     cancelButtonColor: "#9A9A9A",
    //     cancelButtonText: "否",
    //     confirmButtonColor: "#343434"
    // })
    // .then(() =>
    // {
    //     let loginDataStr= localStorage.getItem("loginData");

    //     Toast.success("退出成功");
    //     // 是否授权登录
    //     if (this.userInfo.redirectUri)
    //     {
    //         window.localStorage.clear();
    //         window.location.href = this.userInfo.redirectUri;
    //     }
    //     else
    //     {
    //         window.localStorage.clear();
    //         if(loginDataStr)
    //         {
    //             localStorage.setItem("loginData", loginDataStr);
    //         }
    //         if(this.orgInfo.isSelf)
    //         {
    //             this.$router.push({name: "register"});
    //         }
    //         else
    //         {
    //             this.$router.push({name: "login"});
    //         }

    //     }
    // });
  }

  /**
   * 获取用户详情
   * @private
   * @returns Promise<void>
   */
  private async created(): Promise<void> {
    this.getUserDetail();
  }

  /**
   * 获取企业信息
   * @private
   * @returns any
   */
  private get orgInfo(): any {
    let orgInfoStorge = localStorage.getItem("orgInfo");
    if (orgInfoStorge) {
      return JSON.parse(orgInfoStorge);
    }
    return {};
  }

  /**
   * 取消退出登录
   * @private
   * @returns any
   */
  private async laterView(): Promise<void> {
    this.logoutDialog = false;
  }

  /**
   * 确定退出登录
   * @private
   * @returns any
   */
  private async onAgree(): Promise<void> {
    let loginDataStr = localStorage.getItem("loginData");

    Toast.success("退出成功");
    // 是否授权登录
    if (this.userInfo.redirectUri) {
      window.localStorage.clear();
      window.location.href = this.userInfo.redirectUri;
    } else {
      window.localStorage.clear();
      if (loginDataStr) {
        localStorage.setItem("loginData", loginDataStr);
      }
      if (this.orgInfo.isSelf) {
        this.$router.push({ name: "register" });
      } else {
        this.$router.push({ name: "login" });
      }
    }

    this.logoutDialog = false;
  }

  /**
   * 获取用户详情
   * @private
   * @returns Promise<void>
   */
  private async getUserDetail(): Promise<void> {
    try {
      let { content: result } = await FamilyService.instance.getFamilyDetail(this.userInfo.userId);

      if (result.data) {
        this.modelData = {
          id: result.data.id,
          avatar: result.data.avatar,
          memberType: result.data.memberType,
          memberName: result.data.memberName, // 用户姓名
          familyType: result.data.familyType, // 用户关系
          phone: result.data.phone, // 手机号
          sex: result.data.sex, // 用户性别
          birthday: this.formatBirthday(result.data.birthday), // 生日
          age: result.data.age, // 年龄
          identityCardType: result.data.identityCardType, // 证件类型
          marriageStatus: result.data.marriageStatus, // 用户婚姻状态
          cardNo: result.data.cardNo, // 证件号
          remark: result.data.remark, // 备注
        };
      }
    } catch (err) {
      Toast(err);
    }
  }
}
