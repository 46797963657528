
import { Component, Vue } from "vue-property-decorator";
import { UserService } from "src/services";
import { Toast } from "vant";
import patterns from "src/utils/patterns";
import globalConfig from "src/config/index";

@Component
export default class RetrievePwd extends Vue {
  /**
   * 修改数据模型
   * @private
   * @returns string
   */
  private modelData: any = {
    password: "",
    phone: "",
    smsCode: "",
  };

  /**
   * 点击获取验证码等60秒完成后才可以重新点击发送
   * @private
   * @returns boolean
   */
  private isDisabled: boolean = false;

  /**
   * 是否显示密码
   * @private
   * @returns string
   */
  private showPwd: boolean = false;

  /**
   * 获取验证码
   * @private
   * @returns string
   */
  private sendCode: string = "获取验证码";

  /**
   * 获取验证码60秒
   * @private
   * @returns string
   */
  private time: any = 60;

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get isSubmit(): any {
    return !this.modelData.phone || !this.modelData.smsCode;
  }

  /**
   * 是否从完善信息跳转过来
   * @private
   * @returns void
   */
  private get isPerfect(): any {
    return this.$route.query.isPerfect || "";
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 验证是否正确手机号
   * @private
   * @returns void
   */
  private validatorPhone(val: any): any {
    return patterns.mobile.test(val);
  }

  /**
   * 获取验证码
   * @private
   * @returns void
   */
  private async onGetModifyCode(): Promise<void> {
    try {
      if (!this.modelData.phone) {
        Toast({
          duration: 1000,
          message: "请输入11位手机号",
        });

        return;
      }

      let data = {
        smsType: 6,
        phone: this.modelData.phone,
      };

      await UserService.instance.smsSend(data);

      Toast("验证码发送成功");
      this.isDisabled = true;
      let interval = window.setInterval(() => {
        this.sendCode = `${this.time}s`;
        --this.time;
        if (this.time < 0) {
          this.sendCode = "重新发送";
          this.time = 60;
          this.isDisabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 保存
   * @private
   * @returns void
   */
  private async onSave(): Promise<void> {
    try {
      if (this.isPerfect || this.orgInfo.isSelf) {
        // 完善信息修改
        let data = {
          phone: this.modelData.phone,
          smsCode: this.modelData.smsCode,
        };
        let { content: result } = await UserService.instance.modifyPhoneTwo(data);
      } else {
        // 普通修改手机号
        let { content: result } = await UserService.instance.modifyPhone(this.modelData);
      }

      Toast("修改成功");
      this.$router.go(-1);
    } catch (err) {
      Toast(err);
    }
  }
}
