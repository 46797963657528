
import { Component, Vue } from "vue-property-decorator";
import { AddressService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class AddressList extends Vue {
  /**
   * 当前选中的id
   * @private
   * @returns
   */
  private currentId: any = null;

  /**
   * 是否显示删除确定弹框
   * @private
   * @returns
   */
  protected async loadData(parameter: any): Promise<any> {
    try {
      let { content: res } = await AddressService.instance.getAddressPageList(parameter);
      return res.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 是否显示删除确定弹框
   * @private
   * @returns
   */
  protected async onSetDefault(isDefaultAddress: boolean, id: any): Promise<any> {
    try {
      if (isDefaultAddress) {
        return;
      }
      let { content: res } = await AddressService.instance.setAddressDefault(id);
      (this.$refs["myUPageList"] as any).onRefresh();
      Toast("设置成功");
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 新增收货地址
   * @private
   * @returns
   */
  protected onAddAddress(): void {
    this.$router.push({ name: "address-detail" });
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 编辑
   * @private
   * @returns
   */
  protected onEdit(id: string): void {
    this.$router.push({ name: "address-detail", query: { id: id } });
  }
}
